import React from 'react';
import { Footer } from './Styled';

const Signature = () => (
  <Footer>
    <p>Developed by <a href="https://github.com/pkol92" target="blank">Paulina Kolasa</a></p>
  </Footer>
);
  
export default Signature;
