import styled from 'styled-components'
import img from './photos/11.jpg'

export const Center = styled.div`
  display: grid;
  box-sizing: border-box;
  justify-content: center;
  padding: 1em;
  justify-items: center;
  align-items: center;
`
export const Left = styled.div`
  display: grid;
  box-sizing: border-box;
  padding: 1em;
  justify-items: left;
  align-items: center;
`
export const ContactDiv = styled.div`
  color: black;
  padding: 1em 2em;
  box-sizing: border-box;
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
`
export const Table = styled.table`
  color: black;
  display: grid;
  justify-content: center;
  box-sizing: border-box;
  padding: 1rem;
  padding-bottom: 2rem;
  z-position: relative;
`
export const Tbody = styled.tbody`
  box-sizing: border-box;
  text-align: center;
`
export const Td = styled.td`
  padding: 0.5rem;
  border-radius: 0.28rem;
`
export const Th = styled.th`
  padding: 0.5rem;
  border-radius: 0.28rem;
  background-color: #C0C0C0;
`
export const RodoStyle = styled.div`
  display: grid;
  box-sizing: border-box;
  justify-content: center;
  margin-left: 7rem;
  margin-right: 7rem;
  padding: 1rem;
  padding-bottom: 2rem;
  align-items: center;
  text-align: justify;
  @media (max-width: 700px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`
export const H3 = styled.h3`
  text-align: center;
`
export const Column = styled.div`
  display: grid;
  box-sizing: border-box;
  padding: 2em;
  padding-top: 0;
  grid-template-columns: 2fr 3fr;
  grid-template-rows: auto;
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
`
export const Img = styled.img`
  width: 80%;
  margin: 0 auto;
  display: block;
  border-radius: 0.28rem;
`
export const Paragraph = styled.p`
  text-align: center;
  text-align-last: center;
  font-size: 1.4em;
  @media (max-width: 1000px) {
    font-size: 1em;
  }
`
export const Wrap = styled.div`
  overflow: hidden;
  position: relative;
`
export const Background = styled.div`
  opacity: 0.2;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(${img});
  background-size: cover;
  @media (max-width: 576px) {
    //     height: 400px;
    //   }
`
export const WrapMenu = styled.div`
  margin: 0;
  padding: 0;
`
export const IFrame = styled.iframe`
  border: 2px solid grey;
  border-radius: 0.28rem;
  box-sizing: border-bos;
  margin: 1rem;
  @media (max-width: 800px) {
    height: 80%;
    width: 80%;
  }
`
export const Footer = styled.div`
  text-align: center;
  font-size: 0.7rem;
  background: linear-gradient(to bottom,white 0%,#D0D0D0 100%);
  opacity: 0.8;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
`
export const Text = styled.div`
  text-align: center;
  font-size: 0.7rem;
`
export const CookieStyle = styled.div`
  text-align: justify;
  font-size: 0.9rem;
  padding: 20%;
  padding-top: 1rem;
  @media (max-width: 500px) {
    padding-bottom: 30%;
  }
`
