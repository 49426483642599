import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import item1 from '../photos/1.jpg';
import item2 from '../photos/2.jpg';
import item3 from '../photos/3.jpg';
import item4 from '../photos/5.jpg';
import item5 from '../photos/6.jpg';
import item6 from '../photos/7.jpg';
import { Img, Center } from "../Styled";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    slidesToSlide: 1 
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1 
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 
  }
};
const Items = () => (
  <Carousel
    swipeable={false}
    draggable={false}
    showDots={true}
    responsive={responsive}
    ssr={true} 
    infinite={true}
    autoPlaySpeed={1000}
    keyBoardControl={true}
    customTransition="all .5"
    transitionDuration={500}
    containerClass="carousel-container"
    removeArrowOnDeviceType={["tablet", "mobile"]}
    dotListClass="custom-dot-list-style"
    itemClass="carousel-item-padding-40-px"
    >
    <Center><Img alt="" src={item1}></Img></Center>
    <Center><Img alt="" src={item2}></Img></Center>
    <Center><Img alt="" src={item3}></Img></Center>
    <Center><Img alt="" src={item4}></Img></Center>
    <Center><Img alt="" src={item5}></Img></Center>
    <Center><Img alt="" src={item6}></Img></Center>
  </Carousel>
);

export default Items;
