import React from 'react';
import { Column, Left } from './Styled';
import Items from './Carousel/Carousel';

const Chimney = () => (
    <Column>
      <Left>
        <ul>Oferta Sprzedaży:</ul>
        <li>wkłady kominowe spalinowe dwuścienne białe,</li>
        <li>wkłady kominowe dwuścienne dla wszystkich rodzajów kotłów gazowych,</li>
        <li>kłady kominowe jednościenne dla wszystkich rodzajów kotłów gazowych,</li>
        <li>kominy izolowane,</li>
        <li>adaptery podłączeniowe,</li>
        <li>rury elastyczne kwasoodporne,</li>
        <li>kominy dwuścienne izolowane,</li>
        <li>wkłady kominowe żaroodporne,</li>
        <li>wkłady kominowe żaroodporne owalne,</li>
        <li>kominy dwuścienne żaroodporne,</li>
        <li>kominy hybrydowe,</li>
        <li>przyłącza kominkowe czarne,</li>
        <li>lementy wentylacyjne o przekroju okrągłym i prostokątnym,</li>
        <li>kominy wentylacyjne izolowane,</li>
        <li>nasady kominowe (turbowenty, rotowenty, nasady hybrydowe, wywietrzaki dachowe, „Tulipany, deflektory itp.),</li>
        <li>elementy do dystrybucji ciepłego powietrza z kominka,</li>
        <li>kratki wentylacyjne, kratki kominkowe,</li>
        <li>drzwiczki kominowe,</li>
        <li>generatory ciągu, regulatory ciągu,</li>
        <li>czujniki gazu i czadu,</li>
        <li>rekuperatory marki Vent Clear.</li>
      </Left>
      <Items />
    </Column>
);
  
export default Chimney;
