import React, { Component } from 'react'
import { Menu, Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import { WrapMenu } from './Styled'

export default class MenuExampleInvertedSecondary extends Component {
  state = { activeItem: 'home' }

  handleItemClick = (e: any, { name }: any) => this.setState({ activeItem: name })

  render() {
    const { activeItem } = this.state

    return (
        <WrapMenu>
            <Segment inverted>
            <Menu inverted pointing secondary stackable={true} size='large'>
                <Menu.Item 
                    header as={Link} to="/" 
                    onClick={this.handleItemClick}>
                    Komin Gaz
                </Menu.Item>
                <Menu.Menu position='right'>
                    <Menu.Item
                    as={Link} to="/uslugi_kominiarskie"
                    name='Usługi kominiarskie'
                    active={activeItem === 'Usługi kominiarskie'}
                    onClick={this.handleItemClick}
                    />
                    <Menu.Item
                        as={Link} to="/rekuperacja"
                        name='Rekuperacja'
                        active={activeItem === 'Rekuperacja'}
                        onClick={this.handleItemClick}
                    />
                    <Menu.Item
                        as={Link} to="/o_firmie"
                        name='O firmie'
                        active={activeItem === 'O firmie'}
                        onClick={this.handleItemClick}
                    />
                    <Menu.Item
                        as={Link} to="/cennik"
                        name='Cennik'
                        active={activeItem === 'Cennik'}
                        onClick={this.handleItemClick}
                    />
                    <Menu.Item
                        as={Link} to="/kontakt"
                        name='Kontakt'
                        active={activeItem === 'Kontakt'}
                        onClick={this.handleItemClick}
                    />
                    <Menu.Item
                        as={Link} to="/rodo"
                        name='Rodo'
                        active={activeItem === 'Rodo'}
                        onClick={this.handleItemClick}
                    />
                </Menu.Menu>
            </Menu>
        </Segment>
        </WrapMenu>
        
    )
  }
}
        