import 'semantic-ui-css/semantic.min.css'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Home } from './pages/Home';
import { ContactPage } from './pages/Contact';
import { AboutPage } from './pages/About';
import { PricePage } from './pages/Price';
import { RodoPage } from './pages/Rodo';
import { ChimneyPage } from './pages/Chimney';
import { RecuperationPage } from './pages/Recuperation';
import  NavbarElement  from './components/Navbar';
import Signature from './components/Signature';
import CookieConsent from "react-cookie-consent";
import { Text } from './components/Styled';
import { CookiesPage } from './pages/Cookies';

function App() {
  return (
    <BrowserRouter>
      <NavbarElement />
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/kontakt" component={ContactPage} />
          <Route path="/o_firmie" component={AboutPage} />
          <Route path="/cennik" component={PricePage} />
          <Route path="/rodo" component={RodoPage} />
          <Route path="/uslugi_kominiarskie" component={ChimneyPage} />
          <Route path="/rekuperacja" component={RecuperationPage} />
          <Route path="/cookies" component={CookiesPage} />
        </Switch>
        <Text>
          <CookieConsent 
            buttonText="Zgadzam się!"
            style={{ background: "#2B373B", justifyContent: "center" }}
            buttonStyle={{ color: "#4e503b", fontSize: "0.7rem", borderRadius: "0.28rem" }}
            >Strona używa plików cookies do celów funkcjonalnych oraz statystycznych<a href="/cookies"> Więcej o cookies.</a>
          </CookieConsent>
        </Text>
      <Signature />
    </BrowserRouter>
  );
}

export default App;
