import React from 'react';
import { Column, Img, Center, Paragraph } from './Styled'
import recuperation from './photos/4.jpg'

const Recuperation = () => (
  <Column>
    <Center>
      <Paragraph>
      Jesteśmy oficjalnym przedstawicielem rekuperatorów marki Vent Clear. Oferujemy Państwu pełny zakres usług, od projektu systemu wentylacji mechanicznej z odzyskiem ciepła na podstawie rzutów architektonicznych, po usługi montażu oraz serwisu.
      </Paragraph>
    </Center>
    <Center>
      <Img alt="model rekuperacji" src={recuperation}></Img>
    </Center>
  </Column>
    
);
  
export default Recuperation;
