import React from 'react';
import { Table, Tbody, Td, Th, Wrap, Background } from './Styled'

const tdStyle = {
  border: '1px solid black',
};

const Price = () => {
  const services = [
    { name: 'Opinia kominiarska',  price: "300" },
    { name: 'Opinia kominiarska do sanepidu – z wyliczeniem wydajności',  price: "300 (+ 30 za każdy punkt poboru)" },
    { name: 'Odbiór przewodów kominowych w budynku jednorodzinnym',  price: "300" },
    { name: 'Roczna kontrola przewodów kominowych w domu jednorodzinnym',  price: "300" },
    { name: 'Roczna kontrola przewodów kominowych w domu jednorodzinnym z kontrolą instalacji gazowej',  price: "350" },
    { name: 'Roczna kontrola przewodów kominowych z kontrolą instalacji gazowej w budynkach wielorodzinnych',  price: "wycena indywidualna" },
    { name: 'Czyszczenie przewodów kominkowych w budynku jednorodzinnym',  price: "od 80" },
    { name: 'Czyszczenie mechaniczne przewodu kominowego',  price: "od 250" },
    { name: 'Sprawdzenie przewodu kominowego kamerą inspekcyjną',  price: "od 300" },
    { name: 'Wpuszczenie wkładu typu ALUFOL (cena wraz z materiałem)',  price: "od 100" },
    { name: 'Montaż wkładów kominowych',  price: "od 250 (plus materiał)" },
    { name: 'Udrożnienie przewodu kominowego',  price: "od 200 do 500" },
    { name: 'Udrożnienie przewodu kominowego wraz z wykuciem rewizji i zamurowaniem',  price: "od 400" },
    { name: 'Montaż rekuperacji w budynku jednorodzinnym',  price: "od 4000 (plus materiał)" },
    { name: 'Montaż nasad kominowych',  price: "od 100 (plus nasada)" },
    { name: 'Montaż wentylacji hybrydowej',  price: "wycena indywidualna" },
    { name: 'Montaż czujników gazu i czadu',  price: "100 (plus czujnik)" }
  ];
  return (
    <Wrap>
      <Background></Background>
      <Table>
        <Tbody>
          <tr>
            <Th style={tdStyle}>Usługa</Th>
            <Th style={tdStyle}>Cena (zł)</Th>
          </tr>
          {services.map(({ name, price }) => (
            <tr key={name}>
              <Td style={tdStyle}>{name}</Td>
              <Td style={tdStyle}>{price}</Td>
            </tr>
          ))}
        </Tbody>
      </Table>
    </Wrap>
  );
};
export default Price;
