import React from 'react';
import { ContactDiv, Center, IFrame } from './Styled'

const Contact = () => (
  <ContactDiv>
    <Center>
      <h2>KOMIN-GAZ</h2>
      <h3>Grzegorz Maliszewski</h3>
      <p>ul. Obornicka 34</p>
      <p>62-002 Złotniki</p>
      <p>tel. +48 61 811 05 06</p>
      <p>tel. kom. 604 800 470</p>
      <p>tel. kom. 608 114 817</p>
      <p>komingaz@wp.pl</p> 
    </Center>
    <Center>
      <IFrame src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2429.345445389947!2d16.8458923151859!3d52.49098617980869!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470443b56f286bef%3A0x7e8a9f46cd0f1f11!2sKomin-Gaz.%20Kominy.%20Rekuperacja.%20Sprzeda%C5%BC%2C%20monta%C5%BC!5e0!3m2!1spl!2spl!4v1626708735965!5m2!1spl!2spl" 

      width="400" 
      height="300" 
      allow="fullscreen" 
      loading="lazy"  
      title="Komin Gaz"
      >
      </IFrame>
    </Center>
  </ContactDiv>
);
  
export default Contact;
