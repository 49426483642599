import React from 'react';
import { RodoStyle, H3 } from './Styled';

const Rodo = () => (
    <RodoStyle>
      <H3>Klauzula Informacyjna</H3>
      <p>Zgodnie z art.13 ust.1 i 2 rozporządzenia Parlamentu Europejskiego i Rady (UE)2016/697 z dnia 27 kwietnia 2016r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (Dz.Urz UE L 119/1 z 4.5.2016r.) dalej RODO informuję, że:</p>
      <p>1)	Administratorem/ Podmiotem przetwarzającym Pani/Pana danych jest z PW Komin-Gaz Grzegorz Maliszewski z siedzibą ul. Obornicka 34, 62-002 Złotniki reprezentowana przez Grzegorz Maliszewski</p>
      <p>2)	Pani/Pana dane osobowe przetwarzane są w celu realizacji praw i obowiązków wynikających z Celu i zakresu powierzenia przetwarzania Danych osobowych wynikają bezpośrednio i ograniczają się do zadań lub usług wynikających z przepisów prawnych</p>
      <p>3)	Powierzone Dane osobowe przetwarzane będą przez Podmiot przetwarzający w formie papierowej oraz w systemach informatycznych</p>
      <p>4)	Podmiot przetwarzający oświadcza, że wdrożył odpowiednie środki techniczne, kulturalne i organizacyjne w rozumieniu Rozporządzenia, które zapewniają bezpieczeństwo powierzonych do przetwarzania Danych osobowych</p>
      <p>5)	Odbiorcą Pani/Pana danych będzie PW Komin-Gaz Grzegorz Maliszewski z siedzibą ul. Obornicka 34, 62-002 Złotniki reprezentowana przez Grzegorz Maliszewski,</p>
      <p>6)	Podmiot przetwarzający oświadcza, że wdrożył odpowiednie środki techniczne, kulturowe i organizacyjne zapewniający adekwatny stopień bezpieczeństwa odpowiadający ryzyku związanym z przetwarzaniem powierzonych mu Danych osobowych, o którym mowa w art.32 Rozporządzenia. Podmiot przetwarzający zobowiązuje się do regularnej weryfikacji i aktualizacji stosowanych przez niego środków technicznych, kulturowych i organizacyjnych tak, aby zapewnić powierzonym Danym osobowym adekwatny stopień ochrony.</p>
      <p>7)	Podmiot przetwarzający zobowiązuje się dołożyć należytej staranności przy przetwarzaniu powierzonych Danych osobowych</p>
      <p>8)	Podmiot przetwarzający zobowiązuje się, że pracownicy, osoby świadczące czynności na podstawie umów cywilnoprawnych, inne osoby pracujące na rzecz Podmiotu przetwarzającego), którzy będą przetwarzać Dane osobowe w związku z Umową, będą działać na podstawie wyraźnego upoważnienia do przetwarzania danych osobowych.</p>
      <p>9)	Pani/Pana dane osobowe będą przechowywane przez okres niezbędny warunkujące stosowne przepisy prawne</p>
      <p>10)	Podmiot przetwarzający zobowiązuje się zapewnić zachowanie w tajemnicy (o której mowa w art.28 ust.3 pkt b Rozporządzenia) przetwarzanych Danych osobowych przez osoby, które upoważni do przetwarzania Danych osobowych zarówno w trakcie ich zatrudnienia w Podmiocie przetwarzającym, jak i po jego ustaniu.</p>
      <p>11)	Posiada Pani/Pan prawo dostępu do treści swoich danych oraz prawo ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych, prawo wniesienia sprzeciwu, prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodności z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem</p>
      <p>12)	Ma Pani/Pan prawo do wniesienia skargi do Urzędu ds. Głównego Inspektora Ochrony Danych osobowych, gdy uzna Pani/Pan, że przetwarzanie danych osobowych Pani/Pana dotyczących narusza przepisy RODO.</p>
    </RodoStyle>
);
  
export default Rodo;
