import React from 'react';
import { Column, Img, Center, Paragraph } from './Styled'
import building from './photos/8.jpg'

const About = () => (
    <Column>
      <Center>
        <Paragraph>
          Nasza firma KOMIN-GAZ działa na rynku od 1996 roku. Zajmuje się sprzedażą oraz montażem wkładów kominowych, spalinowych, wentylacyjnych, montażem systemu rekuperacji, nasad kominowych (w tym nasad hybrydowych) oraz frezowaniem kominów.
          Świadczymy w pełnym zakresie usługi kominiarskie.
          W naszej hurtowni znajdą Państwo towar wysokiej jakości. Współpracujemy z firmami Spiroflex, Darco oraz z firmą Alter (czujniki czadu oraz gazu).
        </Paragraph>
      </Center>
      <Center>
        <Img alt="budynek komin gazu" src={building}></Img>
      </Center>
    </Column>
);
  
export default About;
