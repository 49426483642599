import React from 'react';
import { CookieStyle } from './Styled'

const Cookie = () => (
    <CookieStyle>
        <p>CZYM SĄ CIASTECZKA (PLIKI COOKIES)?</p>
        <p>Ciasteczko (cookie) jest plikiem alfanumerycznym (tekstowym), który jest zapisywany na komputerze użytkownika podczas wchodzenia na naszą stronę internetową (lub aktualizowany - w przypadku odwiedzin ponownych/kolejnych).</p>
        <p>W JAKI SPOSÓB WYKORZYSTUJEMY COOKIES W NASZYM SERWISIE?</p>
        <p>Nie gromadzimy ani nie przetwarzamy żadnych informacji pozwalających na bezpośrednią identyfikację danych osobowych. Pliki cookies są wykorzystywane w naszym serwisie do celów statystycznych (dotyczących statystyk odwiedzin naszej witryny) oraz w celu zapewnienia prawidłowego działania serwisu i dostarczenia bardziej dopasowanych treści.</p>
        <p>CZY MOGĘ WYŁĄCZYĆ OBSŁUGĘ COOKIES?</p>
        <p>Tak, możesz to zrobić w każdej chwili poprzez zmianę ustawień swojej przeglądarki w zakresie obsługi cookies.</p>
        <p><b>Wyłączenie lub blokada obsługi cookies może spowodować nieprawidłowe działanie naszej witryny. Jeśli Twoja przeglądarka akceptuje obsługę cookies, dalsze korzystanie z serwisu oznacza, że wyrażasz zgodę na stosowanie cookies przez naszą witrynę."</b></p>
        <p>JAK WYŁĄCZYĆ OBSŁUGĘ CIASTECZEK W POSZCZEGÓLNYCH PRZEGLĄDARKACH?</p>
        <p><b>Google Chrome</b></p>
        <p>Menu (w prawym górnym rogu), Ustawienia, Pokaż ustawienia zaawansowane.</p>
        <p>Sekcja „Prywatność”, Ustawienia treści.</p>
        <p>W sekcji „Pliki cookie” można zmienić następujące ustawienia plików cookie:</p>
        <li>Usuwanie plików cookie</li>
        <li>Domyślne blokowanie plików cookie</li>
        <li>Domyślne zezwalanie na pliki cookie</li>
        <li>Domyślne zachowywanie plików cookie i danych stron do zamknięcia przeglądarki</li>
        <li>Określanie wyjątków dla plików cookie z konkretnych witryn lub domen</li><br></br>
        <p><b>Internet Explorer</b></p>
        <p>Menu przeglądarki: Narzędzia, Opcje Internetowe, Prywatność, przycisk Witryny.</p>
        <p>Suwakiem ustawić poziom, zmianę zatwierdzić przyciskiem OK.</p>
        <p><b>Mozilla Firefox</b></p>
        <p>Menu przeglądarki: Narzędzia, Opcje, Prywatność.</p>
        <p>Uaktywnić pole Program Firefox: „będzie używał ustawień użytkownika”.</p>
        <p>O ciasteczkach (cookies) decyduje zaznaczenie – bądź nie – pozycji Akceptuj ciasteczka.</p>
        <p><b>Opera</b></p>
        <p>Menu przeglądarki: Narzędzie, Preferencje, Zaawansowane.</p>
        <p>O ciasteczkach decyduje zaznaczenie – bądź nie – pozycji Ciasteczka.</p>
        <p><b>Safari</b></p>
        <p>Menu rozwijane Safari: Preferencje, Bezpieczeństwo, wybrać poziom bezpieczeństwa w obszarze "Akceptuj pliki cookie”.</p>
    </CookieStyle>
);
  
export default Cookie;